import { SoccerGameEvent, SoccerGameId } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { SoccerLiveGameTimelineEvent } from "../../soccer-logic";

export function soccerGameEvent__client__liveGameModeEventSubscription(p: {
  soccerGameId: SoccerGameId;
}): BifrostSubscription<SoccerLiveGameTimelineEvent[] | undefined> {
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<SoccerLiveGameTimelineEvent[] | undefined>({
    dispose: () => disposeFns.forEach(fn => fn())
  });
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sub = h.SoccerGameEvent.querySubscription({
    where: [{ soccerGameId: ["==", p.soccerGameId] }, { includeInTimeline: ["==", true] }],
    orderBy: [{ dir: "asc", pathObj: { playingTimeMS: true } }]
  }).subscribe(
    a => {
      instance.nextData(a.docs as SoccerLiveGameTimelineEvent[]);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}
// i18n certified - complete
